import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import baseUrl from "./CONSTANT"
// import Loading from './Loading'

function Otp() {
    const [code, setCode] = useState([])
    const params = useParams()

    useEffect(() => {
        console.log(params.uid)
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/getusershareotp`, { user_id: params.uid }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data.otp)
                setCode(res.data.otp)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [params.uid]);
    console.log(params.uid)
    return (
        <div>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container-fluid table-responsive-md' style={{height:"100vh"}} >
                    <div className='row'>
                        <div className='col-md-12 p-0'>
                            <div className=''>
                                {/* {loading ? 
                                    <div style={{ height: '60vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                         <Loading />
                                    </div>
                                    : */}
                                <div>
                                    <table className="table table-striped table-responsive table-bordered">
                                        <thead className="thead table-dark" >
                                            <tr>
                                                <th scope="col">Sr.No</th>
                                                <th scope="col">OTP</th>
                                                <th scope="col">Date-------Time</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {code.map((codes, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td style={{fontWeight:"bold"}}>{codes.otp}</td>
                                                    <td>{codes.updated_at}</td>
                                                 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otp
