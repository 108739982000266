import React from 'react'

function Searchbar() {
    const handleClick=(()=>{

    })
  return (
    <div className="input-group d-flex justify-content-center">
    <div className="form-outline border border-success">
      <input type="search" id="form1" className="form-control" placeholder='Search' style={{borderRadius:"0px",width:"250px",textAlign:"center"}} />
    </div>
    <button type="button" onClick={handleClick} className="btn btn-primary" style={{borderRadius:"0px 5px 5px 0px",width:"50px"}}>
      <i className="fas fa-search"></i>
    </button>
  </div>
  )
}

export default Searchbar
