import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import { useParams, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import baseUrl from "./CONSTANT"

function TanViewStatusPending() {
    const params = useParams()
    const [userdata, setUserdata] = useState([])
    const [doc, setDoc] = useState([])
    const [fnotes, setNotes] = useState()
    const [statusUid, setStatusuid] = useState('')
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')
    const [staff, setStaff] = useState([])
    const [fdate, setDate] = useState()
    const [fstaffUid, setStaffUid] = useState('')

    const getData = (() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/stafflist`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data.results)
                setStaff(res.data.results)

            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/view-user-detail-tan-reg`, { uid: params.uid }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data.status_data.tanreg)
                setUserdata(res.data.status_data.user)
                setDoc(res.data.status_data.tanreg)
                setStatusuid(res.data.status_data.uid)
            })
            .catch((error) => {
                console.log(error)
            })
        getData()

    }, [params.uid]);
    console.log(params.uid)

    function handleStatus(e) {
        e.preventDefault()
        const uid = statusUid;
        console.log(uid)
        const staff_notes = fnotes;
        const start_date = fdate;
        const staff_uid = fstaffUid;
        console.log(staff_uid)
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/assign-status-tan-reg`, { uid, staff_notes, start_date, staff_uid }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status_data === 200) {
                    setColor("green");
                    console.log('green')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                } else {
                    setColor("red")
                    console.log('red')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container-fluid p-3'>
                    <div className='card'>
                        <div className='row p-3'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Full Name</h6>
                                    </div>
                                    <div className='col-md-3 text-muted '>
                                        <h6>{userdata.full_name}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Gender</h6>
                                    </div>
                                    <div className='col-md-3 text-muted'>
                                        <h6>{userdata.gender}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Email</h6>
                                    </div>
                                    <div className='col-md-6 text-muted'>
                                        <h6>{userdata.email}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Mobile Number</h6>
                                    </div>
                                    <div className='col-md-3 text-muted'>
                                        <h6>{userdata.phone_number}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Aadhar card</h6>
                                    </div>
                                    <div className='col-md-4 text-muted'>
                                        <img src={`${baseUrl}${doc.aadhar}`} style={{ width: "150px", height: "150px" }} alt='aadhar' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.aadhar}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Pan card</h6>
                                    </div>
                                    <div className='col-md-4 '>
                                        <img src={`${baseUrl}${doc.pan}`} style={{ width: "150px", height: "150px" }} alt='pan' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.pan}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <form className='p-3' onSubmit={handleStatus}>
                            <div className="mb-3">
                                <label for="status" style={{ fontWeight: "Bold", fontSize: "18px" }}>Task Assign:</label>
                                <select className='status ms-2' name="staffuid" onChange={(e) => { setStaffUid(e.target.value) }}>
                                    {staff.map((staffs, index) => (
                                     <option key={index} value={`${staffs.id}`}> {staffs.full_name}</option>    
                                    ))}
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label for="task" style={{ fontWeight: "Bold", fontSize: "18px" }}>Task Date:</label>
                                <input className='status ms-2' type="date" id="task" name="task" value={fdate}
                                    onChange={(e) => { setDate(e.target.value) }} required />
                            </div>
                            <div className="mb-3">
                                <label for="message-text" style={{ fontWeight: "Bold" }} className="col-form-label">Message:</label>
                                <textarea className="form-control border border-danger" id="message-text" value={fnotes} onChange={(e) => { setNotes(e.target.value) }} required></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                        </form>
                        <div>
                            <h4 className="text-center m-auto" style={{ backgroundColor: `${color}`, color: 'white', width: '70%' }}>{message}</h4>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default TanViewStatusPending
