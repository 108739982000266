import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import axios from 'axios'
import { useEffect, useState } from 'react'
import baseUrl from "./CONSTANT"
import Loading from './Loading'
import { Link } from 'react-router-dom'

function Userlist() {
    const [userList, setUserlist] = useState([])
    const [loading, setLoading] = useState(true)
    const [squery, setQuery] = useState()
    const [prevUrl, setPrevUrl] = useState('')
    const [nextUrl, setNextUrl] = useState('')

    const active = ((id) => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(id)
        axios.post(`${baseUrl}/adminuser/useractivate/${id}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data)
                getData()
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const deActive = ((id) => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(id)
        axios.post(`${baseUrl}/adminuser/userdeactivate/${id}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data)
                getData()
            })
            .catch((error) => {
                console.log(error)
            })

    })
    const getData = () => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/userlist`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                setUserlist(res.data.results)
                setPrevUrl(res.data.previous)
                setNextUrl(res.data.next)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    function handleNext() {

        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(nextUrl, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                setUserlist(res.data.results)
                setPrevUrl(res.data.previous)
                setNextUrl(res.data.next)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function handlePrev() {
        console.log('btn click')
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(prevUrl, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                setUserlist(res.data.results)
                setPrevUrl(res.data.previous)
                setNextUrl(res.data.next)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //  function handleView(){
    //        navigate('')
    //  }
    function handleSearch() {
        console.log("btym")
        const query = squery
        console.log(query)
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.post(`${baseUrl}/adminuser/userlist`, { query }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                setUserlist(res.data.userlist)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container-fluid table-responsive-md mt-2' style={{ height: "100vh" }}>
                    <div className='row'>
                        <div className="input-group d-flex justify-content-center">
                            <div className="form-outline border border-success">
                                <input type="search" id="form1" onChange={(e) => setQuery(e.target.value)} className="form-control" placeholder='Search' style={{ borderRadius: "0px", width: "250px", textAlign: "center" }} />
                            </div>
                            <button type="button" onClick={handleSearch} className="btn btn-primary" style={{ borderRadius: "0px 5px 5px 0px", width: "50px" }}>
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                        <div className='col-md-12 p-0 mt-2'>
                            <div className=''>
                                {loading ?
                                    <div style={{ height: '60vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loading />
                                    </div>
                                    :
                                    <div>
                                        <table className="table table-striped table-responsive table-bordered">
                                            <thead className="thead table-dark" >
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Gender</th>
                                                    <th scope="col">Phone Number</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">State</th>
                                                    <th scope="col">District</th>
                                                    <th scope="col">Panchayat</th>
                                                    <th scope="col">Block</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    userList.map((userlist, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{userlist.full_name}</td>
                                                            <td>{userlist.gender}</td>
                                                            <td>{userlist.email}</td>
                                                            <td>{userlist.phone_number}</td>
                                                            <td>{userlist.state}</td>
                                                            <td>{userlist.district}</td>
                                                            <td>{userlist.panchayat}</td>
                                                            <td>{userlist.block}</td>
                                                            <td>
                                                                <Link to={`/userotp/${userlist.id}`}>
                                                                    <button className='ms-2 button-9 mb-2'>GET OTP</button>
                                                                </Link>

                                                                {userlist.is_active ?
                                                                    <button className='ms-1 button-9' onClick={() => deActive(`${userlist.id}`)}>Deactivate
                                                                    </button> :
                                                                    <button className='ms-1 button-9' onClick={() => active(`${userlist.id}`)}>Activate</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>

                                    </div>
                                }
                                <div className='d-flex'>
                                    {typeof prevUrl === 'object' ?
                                        <div className='mx-2'>
                                            <button className='mb-3 btn btn-danger' disabled>Prev</button>
                                        </div>
                                        :
                                        <div className='mx-2'>
                                            <button className=' btn btn-success mb-3' onClick={handlePrev} >Prev</button>
                                        </div>
                                    }
                                    {typeof nextUrl === 'object' ?
                                        <div className='mx-2'>
                                            <button className='mb-3 btn btn-danger' disabled> Next</button>
                                        </div>
                                        :
                                        <div className='mx-2'>
                                            <button className='btn btn-success mb-3' onClick={handleNext} >Next</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Userlist
