import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "./Image/GST PANCHAYAT LOGO FINAL 2.png"
import axios from "axios";
import { useState } from 'react';
import baseUrl from "./CONSTANT"

function AdminLogin() {
    const navigate = useNavigate()
    const [message, setMessage] = useState([]);
    const [color, setColor] = useState();

    function submitAdmin(e) {
        e.preventDefault()
        const email = e.target.email.value;
        const password = e.target.password.value;
        axios.post(`${baseUrl}/adminuser/adminlogin`, {email, password })
            .then((response) => {
                setMessage(response.data.message)
                if (response.data.status === 200) {
                    setColor("green");
                    localStorage.setItem('token', JSON.stringify(response.data.access));
                    localStorage.setItem('is_superuser', JSON.stringify(response.data.is_superuser));   
                    setTimeout(() => {
                        navigate('/admin-dashboard')
                    },2000); 

                } else {
                    setColor("#9c6c6c")
                }
                console.log(response)

            })
            .catch((error) => {
                console.log(error)
            })
    


}

return (
    <div className='bg'>
        <div className='text-center'>
            <img src={logo} style={{ width: '100px' }} alt='logo' />
            <div style={{ backgroundColor: '#fa8e4d', width: '100%', margin: '10px 0px 21px 0' }}>
                <h2 style={{ color: '#ffff' }}>GST Panchayat</h2>
            </div>

        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 mx-auto p-3 rounded-2' style={{ backgroundColor: '#5f6870' }}>
                    <form onSubmit={submitAdmin}>
                        <div className='text-center mb-4  mx-auto' style={{
                            fontWeight: 'bold',
                            letterSpacing: '1px', color: 'white' }}>
                            <h4>LOG IN</h4>
                            <h1 className="message-handle" style={{ backgroundColor: `${color}`, color: 'white', fontSize: "1rem" }}>{message}</h1>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="Email" className="form-label" style={{
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                                color: 'white'
                            }}>Email Id</label>
                            <input type="email" className="form-control" name='email' id="email" />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="Password" className="form-label" style={{
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                                color: 'white'
                            }}>Password</label>
                            <input type="password" className="form-control" name='password' id="password" />
                        </div>
                        <button type="submit" className='adminlog mb-4'>LOGIN</button>
                    </form>
                </div>

            </div>

        </div>
    </div>
 )
}

export default AdminLogin;