import React from 'react'
import logo from './Image/GST PANCHAYAT LOGO FINAL 2.png'
import { useNavigate } from 'react-router-dom';
function AdminHeader() {
  const navigate=useNavigate()
  function handleLogOut(){
    localStorage.removeItem('token')
    localStorage.removeItem('is_superuser')
    navigate('/')
  
  }
  return (
    <div className='container-fluid sticky-top '>
      <div className='row'>
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow sticky-top col-md-12">
        <div>
        <a href="/admin-dashboard">
          <img src={logo} alt="this is logo" style={{width:'100px',height:'80px'}}/>
          </a>
          </div>  
          <button type="button" className="button-2"  onClick={handleLogOut}><span>Log Out</span></button>
        </header>
      </div>
    </div>
  )
}

export default AdminHeader
