import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import axios from 'axios'
// import { Button, Modal } from 'react-bootstrap';
import baseUrl from "./CONSTANT"
import Loading from './Loading'

export default function StafList() {
  const [stafflist, setStaffList] = useState([])
  // const [show, setShow] = useState(false);
  // const [fpassword, setFpassword] = useState('');
  const [color, setColor] = useState('')
  const [loading, setLoading] = useState(true)
  const [squery, setQuery] = useState()
  const [prevUrl, setPrevUrl] = useState('')
  const [nextUrl, setNextUrl] = useState('')

  //  const handleSubmit=((e,id)=>{
  //  e.preventDefault()
  //  console.log(id)
  //   const token = JSON.parse(localStorage.getItem('token'))
  //   const password=fpassword
  //   axios.post(`${baseUrl}/adminuser/staffupdate/${id}`,{password},{ headers: { 'Authorization': `Bearer ${token}` } })
  //   .then((res)=>{
  //     console.log(res.data)
  //   })
  //   .catch((error)=>{
  //     console.log(error)
  //   })
  //  });

  // const handleShow = () => {
  //   setShow(true)
  // }
  // const handleClose = () => {
  //   setShow(false)
  //   getData()
  // };

  const remove = ((id) => {
    console.log(id)
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(id)
    axios.post(`${baseUrl}/adminuser/userdelete/${id}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        getData()
      })
      .catch((error) => {
        console.log(error)
      })
  })

  const activate = ((id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(id)
    axios.post(`${baseUrl}/adminuser/useractivate/${id}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        if (res.data.status === 200) {
          setColor("red");
        }
        else {
          setColor("green")
        }
        console.log(res.data)
        getData()
      })
      .catch((error) => {
        console.log(error)
      })
  })
  const deactivate = ((id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(id)
    axios.post(`${baseUrl}/adminuser/userdeactivate/${id}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        getData()
      })
      .catch((error) => {
        console.log(error)
      })
  })

  const getData = (() => {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.get(`${baseUrl}/adminuser/stafflist`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        setStaffList(res.data.results)
        setPrevUrl(res.data.previous)
        setNextUrl(res.data.next)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  })

  useEffect(() => {
    getData()
  }, []);

  const handleSearch = (() => {
    const query = squery
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.post(`${baseUrl}/adminuser/stafflist`, { query }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        setStaffList(res.data.stafflist)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  })

  function  handleNext(){

    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.get(nextUrl, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            console.log(res.data)
            setStaffList(res.data.results)
            setPrevUrl(res.data.previous)
            setNextUrl(res.data.next)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
        })
   }

   function  handlePrev(){
    console.log('btn click')
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.get(prevUrl, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            console.log(res.data)
            setStaffList(res.data.results)
            setPrevUrl(res.data.previous)
            setNextUrl(res.data.next)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
        })
   }

  return (
    <div>
      <AdminHeader />
      <div className='d-flex'>
        <div>
          <AdminSide />
        </div>
        <div className='container-fluid mt-2' style={{ height: "100vh" }}>
          <div className='row'>
            <div className="input-group d-flex justify-content-center">
              <div className="form-outline border border-success">
                <input type="search" id="form1" onChange={(e) => setQuery(e.target.value)} className="form-control" placeholder='Search' style={{ borderRadius: "0px", width: "250px", textAlign: "center" }} />
              </div>
              <button type="button" onClick={handleSearch} className="btn btn-primary" style={{ borderRadius: "0px 5px 5px 0px", width: "50px" }}>
                <i className="fas fa-search"></i>
              </button>
            </div>
            <div className='col-md-12 p-0 mt-2'>
              {loading ?
                <div style={{ height: '60vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Loading />
                </div>
                :
                <div>
                  <div className='table-responsive-md'>
                    <table className="table table-striped table-bordered">
                      <thead className="thead table-dark" >
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Email</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stafflist.map((staf, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{staf.full_name}</td>
                            <td>{staf.gender}</td>
                            <td>{staf.phone_number}</td>
                            <td>{staf.email}</td>
                            <td style={{ width: '233px' }}>
                              {staf.is_active ? <button className='ms-3 button-9' onClick={() => deactivate(`${staf.id}`)}>Deactivate
                              </button> :
                                <button className='ms-3 button-9' style={{ backgroundColor: `${color}`, color: 'white' }} onClick={() => activate(`${staf.id}`)} >Activate</button>}
                              {/* <button className='ms-1 button-9'> <i class="fas fa-user-edit fa-lg"></i></button> */}
                              <button className='ms-3 button-9' onClick={() => remove(`${staf.id}`)}><i className="fas fa-trash-alt fa-lg"></i></button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    
                    <div className='d-flex'>
                                    {typeof prevUrl === 'object' ?
                                        <div className='mx-2'>
                                            <button className='mb-3 btn btn-danger' disabled>Prev</button>
                                        </div>
                                        :
                                        <div className='mx-2'>
                                            <button className=' btn btn-success mb-3' onClick={handlePrev} >Prev</button>
                                        </div>
                                    }
                                    {typeof nextUrl === 'object' ?
                                        <div className='mx-2'>
                                            <button className='mb-3 btn btn-danger' disabled> Next</button>
                                        </div>
                                        :
                                        <div className='mx-2'>
                                            <button className='btn btn-success mb-3' onClick={handleNext} >Next</button>
                                        </div>
                                    }
                                </div>
                    
         
                  </div>
                </div>
              }
            </div>

          </div>
        </div>

      </div>
      {/*---------------------------------MOdAL--------------------------------------------------------- */}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label for="password" className="form-label text-black">Password</label>
              <input type="password" name="fpassword" className="form-control" id="password" value={fpassword} onChange={(e) => {setFpassword(e.target.value) }}/>
            </div>
            <Button type='submit' variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

    </div>
  )
}
