import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import { useParams, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import baseUrl from "./CONSTANT"

function ReturnViewStatusAccept() {
    const params = useParams()
    const [userdata, setUserdata] = useState([])
    const [doc, setDoc] = useState([])
    const [fstatus, setStatus] = useState()
    const [fnotes, setNotes] = useState()
    const [statusUid, setStatusuid] = useState('')
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')
    const [staff, setStaff] = useState('')
    const [mnotes, setMNotes] = useState('')
    const [date, setDate] = useState('')
    const [otp, setOtp] = useState('')
    

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/view-user-detail-gst-return`, { uid: params.uid }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data.status_data)
                console.log(res.data.status_data.updated_at)
                setUserdata(res.data.status_data.user)
                setDoc(res.data.status_data.gstreturn)
                setStatusuid(res.data.status_data.uid)
                setStaff(res.data.status_data.staff_user.full_name)
                setMNotes(res.data.status_data.staff_notes)
                setDate(res.data.status_data.updated_at)
                setOtp(res.data.status_data)
            
            })
            .catch((error) => {
                console.log(error)
            })

    }, [params.uid]);
    console.log(params.uid)

    function handleStatus(e) {
        e.preventDefault()
        console.log(fstatus)
        console.log(statusUid)
        const gstret_uid = statusUid;
        const user_id = userdata.id;
        const notes = fnotes;
        const status = fstatus
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/change-status-gst-return`, { gstret_uid, user_id, status, notes }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status_data === 200) {
                    setColor("green");
                    console.log('green')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                } else {
                    setColor("red")
                    console.log('red')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    return (
        <div>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container-fluid p-3'>
                    <div className='card'>
                    <div className='row p-4'>
                            <div className='col-md-8 '>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Full Name</h6>
                                    </div>
                                    <div className='col-md-6 text-muted '>
                                        <h6>{userdata.full_name}</h6>
                                    </div>

                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Gender</h6>
                                    </div>
                                    <div className='col-md-6 text-muted'>
                                        <h6>{userdata.gender}</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Email</h6>
                                    </div>
                                    <div className='col-md-6 text-muted'>
                                        <h6>{userdata.email}</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Mobile Number</h6>
                                    </div>
                                    <div className='col-md-3 text-muted'>
                                        <h6>{userdata.phone_number}</h6>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className='col-md-4' style={{ backgroundColor: "#2e5456", color: "white",height:"190px" }}>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Task Assign</h6>
                                    </div>
                                    <div>
                                        <h6>{staff}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Message</h6>
                                    </div>
                                    <div>
                                        <h6>{mnotes}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Date</h6>
                                    </div>
                                    <div>
                                        <h6>{date}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>OTP</h6>
                                    </div>
                                    <div>
                                        <h6>{otp.otp}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>OTP Date</h6>
                                    </div>
                                    <div>
                                        <h6>{otp.otp_date}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Payment Detail</h6>
                                    </div>
                                    <div className='col-md-4 text-muted'>
                                        <img src={`${baseUrl}${doc.payment_detail
                                            }`} style={{ width: "150px", height: "150px" }} alt='aadhar' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.aadhar}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Sale Invoice</h6>
                                    </div>
                                    <div className='col-md-4 '>
                                        <img src={`${baseUrl}${doc.sale_invoice
                                            }`} style={{ width: "150px", height: "150px" }} alt='pan' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.pan}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Pan card</h6>
                                    </div>
                                    <div className='col-md-4 '>
                                        <img src={`${baseUrl}${doc.pan}`} style={{ width: "150px", height: "150px" }} alt='pan' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.pan}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <form className='p-3' onSubmit={handleStatus}>
                            <div className="mb-3">
                                <label for="status" style={{ fontWeight: "Bold", fontSize: "18px" }}>Change Status:</label>
                                <select className='status ms-2' id="status" value={fstatus} onChange={(e) => { setStatus(e.target.value) }}>
                                    <option value="select">Select Status</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Pending">Pending</option>

                                </select>
                            </div>
                            <div className="mb-3">
                                <label for="message-text" style={{ fontWeight: "Bold" }} className="col-form-label">Message:</label>
                                <textarea className="form-control border border-danger" id="message-text" value={fnotes} onChange={(e) => { setNotes(e.target.value) }} required></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                        </form>
                        <div>
                            <h4 className="text-center m-auto" style={{ backgroundColor: `${color}`, color: 'white', width: '70%' }}>{message}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnViewStatusAccept
