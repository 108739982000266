import React, { useEffect } from 'react'
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';


function AdminSide() {
  const [gst, setGst] = useState('none')
  const [tan, setTan] = useState('none')
  const [filling, setFilling] = useState('none')
  const [task, setTask] = useState('none')
  const [superUser, setSuperUser] = useState()


  useEffect(() => {
    setSuperUser(JSON.parse(localStorage.getItem('is_superuser')))
  }, [])
  const showGst = () => {
    if (gst === "inline") {
      setGst('none')
    }
    else {
      setGst('inline')
    }
  }

  const showTan = () => {
    if (tan === "inline") {
      setTan('none')
    }
    else {
      setTan('inline')
    }
  }
  const showReturn = () => {
    if (filling === "inline") {
      setFilling('none')
    }
    else {
      setFilling('inline')
    }
  }

  const showTask = () => {
    if (task === 'block') {
      setTask("none")
    } else {
      setTask("block")
    }
  }

  return (
    <>

      <div style={{ display: 'flex', height: '100%', overflow: 'scroll initial' }}>
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <a href="/admin-dashboard" className="text-decoration-none" style={{
              color: 'inherit', fontWeight: 'bold',
              letterSpacing: '1px'
            }}>
              User Panel
            </a>
          </CDBSidebarHeader>
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink to="/admin-dashboard" activeclassname="activeClicked">
                <CDBSidebarMenuItem icon="columns" style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}>Dashboard</CDBSidebarMenuItem>
              </NavLink>

              <CDBSidebarMenuItem icon="columns" onClick={showTask} style={{
                fontWeight: 'bold',
                letterSpacing: '1px'
              }}>All Task
              </CDBSidebarMenuItem>

              <CDBSidebarMenuItem icon="columns" onClick={showGst}
                style={{
                  display: `${task}`,
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "rgb(78 59 59)",
                  margin: "0px"
                }}>GST Registration  Status
              </CDBSidebarMenuItem>
              {superUser ? <NavLink to="/gststatus-pending" style={{ display: `${gst}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Pending
                </div>
              </NavLink> :
                <div></div>
              }

              <NavLink to="/gststatus-accepted" style={{ display: `${gst}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Accepted
                </div>
              </NavLink>
              <NavLink to="/gststatus-rejected" style={{ display: `${gst}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Rejected
                </div>
              </NavLink>

              <NavLink to="/gststatus-completed" style={{ display: `${gst}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Completed
                </div>
              </NavLink>


              <CDBSidebarMenuItem icon="columns" onClick={showTan} style={{
                display: `${task}`,
                fontWeight: 'bold',
                letterSpacing: '1px',
                backgroundColor: "rgb(78 59 59)",
                padding: "9px",
                margin: "0px"
              }}>TAN Registration Status</CDBSidebarMenuItem>
               
               {superUser ? <NavLink to="/tanstatus-pending" style={{ display: `${tan}` }}          activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Pending
                </div>
              </NavLink>:
              <div></div>}
              
              <NavLink to="/tanstatus-accepted" style={{ display: `${tan}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Accepted
                </div>
              </NavLink>
              <NavLink to="/tanstatus-reject" style={{ display: `${tan}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Rejected
                </div>
              </NavLink>
              <NavLink to="/tanstatus-completed" style={{ display: `${tan}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Completed
                </div>
              </NavLink>

              <CDBSidebarMenuItem icon="columns" onClick={showReturn} style={{
                display: `${task}`,
                fontWeight: 'bold',
                letterSpacing: '1px',
                backgroundColor: "rgb(78 59 59)",
                padding: "9px",
                margin: "0px"
              }}> GST Return Status</CDBSidebarMenuItem>

              {superUser ? <NavLink to="/returnstatus-pending" style={{ display: `${filling}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Pending
                </div>
              </NavLink> :
                <div></div>}

              <NavLink to="/returnstatus-accepted" style={{ display: `${filling}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Accepted
                </div>
              </NavLink>

              <NavLink to="/returnstatus-reject" style={{ display: `${filling}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Rejected
                </div>
              </NavLink>

              <NavLink to="/returnstatus-completed" style={{ display: `${filling}` }} activeclassname="activeClicked">
                <div className='text-center' style={{
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  padding: '9px',
                  backgroundColor: "#595959"
                }}>
                  Completed
                </div>
              </NavLink>

              {superUser ?
                <div>
                  <NavLink to="/all-user" activeclassname="activeClicked">
                    <CDBSidebarMenuItem icon="user" style={{
                      fontWeight: 'bold',
                      letterSpacing: '1px'
                    }}>All User</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink to="/all-staff" activeclassname="activeClicked">
                    <CDBSidebarMenuItem icon="user" style={{
                      fontWeight: 'bold',
                      letterSpacing: '1px'
                    }}>All Staff</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink to="/add-staff" activeclassname="activeClicked">
                    <CDBSidebarMenuItem icon="user" style={{
                      fontWeight: 'bold',
                      letterSpacing: '1px'
                    }}>Add Staff</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                :
                <div></div>

              }

            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      </div>
    </>
  );
}

export default AdminSide;
