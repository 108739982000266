import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import { useEffect, useState } from 'react';
import axios from 'axios'
import baseUrl from './CONSTANT';

function AdminDashBoard() {
    const [user, setUser] = useState([])
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.get(`${baseUrl}/adminuser/dashboard`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res)
                setUser(res.data)

            })
            .catch((error) => {
                console.log(error)
            })

    }, [])

    return (
        <>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container'>
                    <div className='row justify-content-center mt-3'>
                        <div className='col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded  me-3' style={{ backgroundColor: "#5e2a40", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Total User
                            </span>
                            <div className='totaluser'>{user.user_count}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#91a6ff", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Total Staff
                            </span>
                            <div className='totaluser'>{user.staff_count}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded' style={{ backgroundColor: "#fd7e80", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                              Active User
                            </span>
                            <div className='totaluser'>{user.total_active_user_count}</div>
                        </div>
                    </div>
                    <h4 className='text-center'>Gst Registration</h4>
                    <div className='row justify-content-center mt-4'>
                        <div className='col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#084298", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Pending
                            </span>
                            <div className='totaluser'>{user.pending_gst_reg_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#db0015", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Rejected
                            </span>
                            <div className='totaluser'>{user.rejected_gst_reg_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#0dcaf0", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Accepted
                            </span>
                            <div className='totaluser'>{user.accepted_gst_reg_status}</div>
                        </div>
                        <div className='d col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#198754", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Completed
                            </span>
                            <div className='totaluser'>{user.completed_gst_reg_status}</div>
                        </div>
                    </div>
                    <h4 className='text-center'>Tan Registration </h4>
                    <div className='row justify-content-center mt-4'>
                        <div className='col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#084298", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Pending
                            </span>
                            <div className='totaluser'>{user.pending_tan_reg_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#db0015", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Rejected
                            </span>
                            <div className='totaluser'>{user.rejected_tan_reg_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#0dcaf0", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Accepted
                            </span>
                            <div className='totaluser'>{user.accepted_tan_reg_status}</div>
                        </div>
                        <div className='d col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#198754", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Completed
                            </span>
                            <div className='totaluser'>{user.completed_tan_reg_status}</div>
                        </div>
                    </div>
                    <h4 className='text-center'>Gst Return</h4>
                    <div className='row justify-content-center mt-4'>
                        <div className='col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#084298", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Pending
                            </span>
                            <div className='totaluser'>{user.pending_gst_return_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#db0015", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Rejected
                            </span>
                            <div className='totaluser'>{user.rejected_gst_return_status}</div>
                        </div>
                        <div className='d col-md-3  shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#0dcaf0", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Accepted
                            </span>
                            <div className='totaluser'>{user.accepted_gst_return_status}</div>
                        </div>
                        <div className='d col-md-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded me-3' style={{ backgroundColor: "#198754", color: "white", width: "200px" }}>
                            <span className='totaluser'>
                                Completed
                            </span>
                            <div className='totaluser'>{user.completed_gst_return_status}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AdminDashBoard
