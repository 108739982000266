import React from 'react'

function Loading() {
  return (
    <div className="container-lod">
         <div className="wrapper">
            <div className="loader">
               <div className="dot"></div>
            </div>
            <div className="loader">
               <div className="dot"></div>
            </div>
            <div className="loader">
               <div className="dot"></div>
            </div>
            <div className="loader">
               <div className="dot"></div>
            </div>
            <div className="loader">
               <div className="dot"></div>
            </div>
            <div className="loader">
               <div className="dot"></div>
            </div>
         </div>
         <div className="text-t">
            Please wait...
         </div>
      </div>
  )
}

export default Loading
