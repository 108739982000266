import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import { useParams, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import baseUrl from "./CONSTANT"

function GstViewStatusComplete() {
    const params = useParams()
    const [userdata, setUserdata] = useState([])
    const [doc, setDoc] = useState([])
    const [staff, setStaff] = useState('')
    const [mnotes, setMNotes] = useState('')
    const [date, setDate] = useState('')
    const [otp, setOtp] = useState('')
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        axios.post(`${baseUrl}/adminuser/view-user-detail-gst-reg`, { uid: params.uid }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data.status_data)
                setUserdata(res.data.status_data.user)
                setDoc(res.data.status_data.gstreg)
                setStaff(res.data.status_data.staff_user.full_name)
                setMNotes(res.data.status_data.staff_notes)
                setDate(res.data.status_data.updated_at)
                setOtp(res.data.status_data)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [params.uid]);
    console.log(params.uid)

    return (
        <div>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container-fluid p-3'>
                    <div className='card'>
                        <div className='row p-4'>
                            <div className='col-md-8 '>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Full Name</h6>
                                    </div>
                                    <div className='col-md-6 text-muted '>
                                        <h6>{userdata.full_name}</h6>
                                    </div>

                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Gender</h6>
                                    </div>
                                    <div className='col-md-6 text-muted'>
                                        <h6>{userdata.gender}</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Email</h6>
                                    </div>
                                    <div className='col-md-6 text-muted'>
                                        <h6>{userdata.email}</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <h6>Mobile Number</h6>
                                    </div>
                                    <div className='col-md-3 text-muted'>
                                        <h6>{userdata.phone_number}</h6>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className='col-md-4' style={{ backgroundColor: "#2e5456", color: "white",height:"190px" }}>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Task Assign</h6>
                                    </div>
                                    <div>
                                        <h6>{staff}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Message</h6>
                                    </div>
                                    <div>
                                        <h6>{mnotes}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>Date</h6>
                                    </div>
                                    <div>
                                        <h6>{date}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>OTP</h6>
                                    </div>
                                    <div>
                                        <h6>{otp.otp}</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between px-4 mt-2' >
                                    <div>
                                        <h6>OTP Date</h6>
                                    </div>
                                    <div>
                                        <h6>{otp.otp_date}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                     
                      
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Aadhar card</h6>
                                    </div>
                                    <div className='col-md-4 text-muted'>
                                        <img src={`${baseUrl}${doc.aadhar}`} style={{ width: "150px", height: "150px" }} alt='aadhar' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.aadhar}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Pan card</h6>
                                    </div>
                                    <div className='col-md-4 '>
                                        <img src={`${baseUrl}${doc.pan}`} style={{ width: "150px", height: "150px" }} alt='pan' />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.pan}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h6>Address </h6>
                                    </div>
                                    <div className='col-md-4'>
                                        <img src={`${baseUrl}${doc.panchyat_address_proof
                                            }`} style={{ width: "150px", height: "150px" }} alt="address" />
                                    </div>
                                    <div className='col-md-4'>
                                        <button class="cssbuttons-io-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"></path></svg>
                                            <Link className="text" to={`${baseUrl}${doc.panchyat_address_proof}`} target="_blank">  Download</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default GstViewStatusComplete
