import React, { useState } from 'react'
import AdminHeader from '../Component/AdminHeader'
import AdminSide from '../Component/AdminSide'
import axios from "axios"
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import baseUrl from "./CONSTANT"
import Loading from './Loading'

function GstStatusAccepted() {
  const [gstUser, setGstUser] = useState([])
  const [loading, setLoading] = useState(true)
  const [squery, setQuery] = useState()
  const [prevUrl, setPrevUrl] = useState('')
  const [nextUrl, setNextUrl] = useState('')

  const getData = (() => {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.post(`${baseUrl}/adminuser/active-status-gst-registration`, { 'status': "Accepted" }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        setGstUser(res.data.results)
        setPrevUrl(res.data.previous)
        setNextUrl(res.data.next)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  })

  useEffect(() => {
    getData()
  }, []);

  function handleNext() {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.post(nextUrl,{ 'status': "Accepted" }, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            console.log(res.data)
            setGstUser(res.data.results)
            setPrevUrl(res.data.previous)
            setNextUrl(res.data.next)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
        })
}

function handlePrev() {
    console.log('btn click')
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.post(prevUrl, { 'status': "Accepted" },{ headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            console.log(res.data)
            setGstUser(res.data.results)
            setPrevUrl(res.data.previous)
            setNextUrl(res.data.next)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
        })
}

  const handleSearch = (() => {
    const query = squery
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    axios.post(`${baseUrl}/adminuser/active-status-gst-registration`, { 'status': "Accepted", query }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data)
        setGstUser(res.data.status_data)
      })
      .catch((error) => {
        console.log(error)
      })
  })

  return (
    <div>
      <AdminHeader />
      <div className='d-flex'>
        <div>
          <AdminSide />
        </div>
        <div className='container-fluid table-responsive-md mt-2' style={{ height: "100vh" }}>
          <div className='row'>
            <div className='mt-2'>
              <div className="input-group d-flex justify-content-center">
                <div className="form-outline border border-success">
                  <input type="search" id="form1" onChange={(e) => setQuery(e.target.value)} className="form-control" placeholder='Search' style={{ borderRadius: "0px", width: "250px", textAlign: "center" }} />
                </div>
                <button type="button" onClick={handleSearch} className="btn btn-primary" style={{ borderRadius: "0px 5px 5px 0px", width: "50px" }}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <div className='col-md-12 p-0 table-responsive-md mt-2'>
              <div className=''>
                {loading ?
                  <div style={{ height: '60vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loading />
                  </div>
                  :
                  <div>
                    <table className="table table-striped table-bordered">
                      <thead className="thead table-dark">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Email</th>
                          <th scope="col">Applied Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Message</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gstUser.map((data, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{data.user.full_name}</td>
                            <td>{data.user.gender}</td>
                            <td>{data.user.phone_number}</td>
                            <td>{data.user.email}</td>
                            <td>{data.updated_at
                            }</td>
                            <td>{data.status}</td>
                            <td>{data.notes}</td>
                            <td>
                              <Link to={`/viewstatusaccept/${data.uid}`}>
                                <button className='ms-1 button-9'>View Document</button>
                              </Link>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
                <div className='d-flex'>
                  {typeof prevUrl === 'object' ?
                    <div className='mx-2'>
                      <button className='mb-3 btn btn-danger' disabled>Prev</button>
                    </div>
                    :
                    <div className='mx-2'>
                      <button className=' btn btn-success mb-3' onClick={handlePrev} >Prev</button>
                    </div>
                  }
                  {typeof nextUrl === 'object' ?
                    <div className='mx-2'>
                      <button className='mb-3 btn btn-danger' disabled> Next</button>
                    </div>
                    :
                    <div className='mx-2'>
                      <button className='btn btn-success mb-3' onClick={handleNext} >Next</button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GstStatusAccepted
