import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import AdminLogin from './Component/AdminLogin'
import AdminDashBoard from './Component/AdminDashBoard'
import Userlist from './Component/Userlist';
import StafList from './Component/StafList';
import AddStaf from './Component/AddStaf';
import GstStatusPending from './GstStatusPending';
import GstStatusAccepted from "./Component/GstStatusAccepted"
import GstStatusReject from './Component/GstStatusReject';
import TanStatusPending from './Component/TanStatusPending';
import TanStatusAccept from './Component/TanStatusAccept';
import TanStatusReject from './Component/TanStatusReject';
import ReturnStatusPending from './Component/ReturnStatusPending';
import ReturnStatusAccept from './Component/ReturnStatusAccept';
import ReturnStatusReject from './Component/ReturnStatusReject';
import GstStatusComplted from './Component/GstStatusComplted';
import TanStatusCompleted from './Component/TanStatusCompleted';
import ReturnStatusCompletd from './Component/ReturnStatusCompletd';
import GstViewStatusPending from './Component/GstViewStatusPending';
import GstViewStatusAccept from './Component/GstStatusViewAccept';
import GstViewStatusReject from './Component/GstViewStatusReject';
import GstViewStatusComplete from './Component/GstViewStatusComplete';
import TanViewStatusPending from './Component/TanViewStatusPending';
import TanViewStatusAccepted from './Component/TanViewStatusAccepted';
import TanViewStatusReject from './Component/TanViewStatusReject';
import TanViewStatusComplete from './Component/TanViewStatusComplete';
import ReturnViewStatusPending from './Component/ReturnViewStatusPending';
import ReturnViewStatusAccept from './Component/ReturnViewStatusAccept';
import ReturnViewStatusReject from './Component/ReturnViewStatusReject';
import ReturnViewStatusComplete from './Component/ReturnViewStatusComplete';
import PageNotFound from './Component/PageNotFound';
import Searchbar from './Component/Searchbar';
import Otp from './Component/Otp';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminDashBoard />} />
        <Route path="/all-user" element={<Userlist />} />
        <Route path="/all-staff" element={<StafList />} />
        <Route path="/add-staff" element={<AddStaf />} />
        <Route path="/gststatus-pending" element={<GstStatusPending />} />
        <Route path="/gststatus-accepted" element={<GstStatusAccepted />} />
        <Route path="/gststatus-rejected" element={<GstStatusReject />} />
        <Route path="/gststatus-completed" element={<GstStatusComplted />} />
        <Route path="/tanstatus-pending" element={<TanStatusPending />} />
        <Route path="/tanstatus-accepted" element={<TanStatusAccept />} />
        <Route path="/tanstatus-reject" element={<TanStatusReject />} />
        <Route path="/tanstatus-completed" element={<TanStatusCompleted />} />
        <Route path="/returnstatus-pending" element={<ReturnStatusPending />} />
        <Route path="/returnstatus-accepted" element={<ReturnStatusAccept />} />
        <Route path="/returnstatus-reject" element={<ReturnStatusReject />} />
        <Route path="/returnstatus-completed" element={<ReturnStatusCompletd />} />
        <Route path="/viewstatus/:uid" element={<GstViewStatusPending />} />
        <Route path="/viewstatusaccept/:uid" element={<GstViewStatusAccept />} />
        <Route path="/viewstatusreject/:uid" element={<GstViewStatusReject />} />
        <Route path="/viewstatuscomplete/:uid" element={<GstViewStatusComplete />} />
        <Route path="/tanviewstatuspending/:uid" element={<TanViewStatusPending />} />
        <Route path="/tanviewstatusaccept/:uid" element={<TanViewStatusAccepted />} />
        <Route path="/tanviewstatusreject/:uid" element={<TanViewStatusReject />} />
        <Route path="/tanviewstatuscomplete/:uid" element={<TanViewStatusComplete />} />
        <Route path="/returnviewstatuspending/:uid" element={<ReturnViewStatusPending />} />
        <Route path="/returnviewstatusaccept/:uid" element={<ReturnViewStatusAccept />} />
        <Route path="/returnviewstatusreject/:uid" element={<ReturnViewStatusReject />} />
        <Route path="/returnviewstatuscomplete/:uid" element={<ReturnViewStatusComplete />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="search" element={<Searchbar />} />
        <Route path="userotp/:uid" element={<Otp />} />
       

      </Routes>
    </div>
  );
}

export default App;
