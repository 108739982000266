import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import AdminSide from './AdminSide'
import axios from 'axios'
import baseUrl from './CONSTANT'

function AddStaf() {
    const [fname, setFname] = useState('');
    const [femail, setFemail] = useState('');
    const [fgender, setFgender] = useState('');
    const [phone, setPhone] = useState('');
    const [fpassword, setFpassword] = useState('');
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')


    const handleSubmit = ((e) => {
        e.preventDefault()
        const full_name = fname;
        const email = femail;
        const phone_number = phone;
        const gender = fgender;
        const password = fpassword
        const token = JSON.parse(localStorage.getItem('token'))
        console.log(token)
        axios.post(`${baseUrl}/adminuser/add-staff`, { full_name, email, phone_number, gender, password }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((res) => {
                console.log(res)
                setMessage(res.data.message)
                if (res.data.status === 200) {
                    setColor("green");
                    console.log('green')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                } else {
                    setColor("red")
                    console.log('red')
                    setTimeout(() => {
                        setMessage('')
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error)
            })

    })
    return (
        <>
            <AdminHeader />
            <div className='d-flex'>
                <div>
                    <AdminSide />
                </div>
                <div className='container mt-2' style={{height:"100vh"}}>
                    <div className='row'>
                        <div className='col-md-6 mx-auto'>
                            <div className='card border-dark' style={{ backgroundColor: '#6B9189' }}>
                                <div className='card-body '>
                                    <div className='form-group'>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label text-white" style={{ fontWeight: '500' }}>Full Name</label>
                                                <input type="name" name="fname" className="form-control" id="name" value={fname} onChange={(e) => { setFname(e.target.value) }} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label text-white" style={{ fontWeight: '500' }}>Email Id</label>
                                                <input type="email" name="email" className="form-control" id="email" value={femail} onChange={(e) => { setFemail(e.target.value) }} />
                                            </div>
                                            <hr />
                                            <div className="mb-3">
                                                <label htmlFor="gender" className="form-label text-white" style={{ fontWeight: '500' }}>Gender</label><br />
                                                <select name="gender"  className="form-control"  id="gender" style={{ fontWeight: '500'}} value={fgender} onChange={(e) => { setFgender(e.target.value) }}>
                                                    <option value="" style={{ fontWeight: '500' }}>Select</option>
                                                    <option value="male" style={{ fontWeight: '500' }}>Male</option>
                                                    <option value="female" style={{ fontWeight: '500' }}>Female</option>
                                                    </select>
                                                {/* <input className="form-check-input text-black" type="radio" name="gender" id="male" style={{ fontWeight: '500' }} value={fgender} onChange={(e) => { setFgender(e.target.value) }} />
                                                <span className='text-white ms-2' style={{ fontWeight: '500' }}>Male</span><br />
                                                <input className="form-check-input" type="radio" name="gender" id="Female" value={fgender} onChange={(e) => { setFgender(e.target.value) }} />
                                                <span className="text-white ms-2" style={{ fontWeight: '500' }}>Female</span> */}
                                            </div>
                                            <hr />
                                            <div className="mb-3">
                                                <label htmlFor="phone" className="form-label text-white" style={{ fontWeight: '500' }}>Phone Number</label>
                                                <input type="phone" name="phone_number" className="form-control" id="phone" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label text-white" style={{ fontWeight: '500' }}>Password</label>
                                                <input type="current-password" name="password" className="form-control" id="password" value={fpassword} onChange={(e) => { setFpassword(e.target.value) }} />
                                            </div>
                                            <div className='text-center'>
                                                <button className="staff-btn">Submit
                                                </button>
                                                <div className='mt-2'>
                                                    <h4 className="text-center m-auto" style={{ backgroundColor: `${color}`, color: 'white', width: '70%' }}>{message}</h4>
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </>
    )
}

export default AddStaf
